<template>
  <main>
    <b-card>
      <section class="pb-10">
        <ul class="stores__grid">
          <li
            v-for="store in stores"
            :key="store.id"
            class="store shadow-sm px-1 py-2"
          >
            <b-form-checkbox
              :id="store.id"
              :value="store.id"
              name="stores"
              v-model="selectedStores"
            >
              <b-img
                :src="store.logo"
                alt="store.name"
                width="50"
                height="50"
                class="mr-50"
              />
              <span>
                {{ store.name }}
              </span>
            </b-form-checkbox>
          </li>
        </ul>

        <div class="mx-2 mb-2 mt-2">
          <pagination
            :loading="loading"
            :entriesPerPage.sync="entriesPerPage"
            :handlePagination="handlePagination"
            :pagination="pagination"
          />
        </div>
      </section>

      <div class="buttons">
        <b-button to="/suppliers" variant="outline-secondary">Volver</b-button>

        <div>
          <span class="mr-1"
            >{{ selectedStores.length }} tiendas seleccionadas</span
          >

          <b-button
            variant="primary"
            @click="handleAttachStoresToSupplier(selectedStores)"
          >
            Anexar Proveedor
          </b-button>
        </div>
      </div>
    </b-card>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import Pagination from "@/@core/components/Pagination.vue"

import messagesMixin from "@core/mixins/messagesMixin"

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      establishmentId: this.$route.params.id,
      supplierId: this.$route.params.supplier_id,
      entriesPerPage: "10",
      pagination: {},
      loading: false,
      selectedStores: [],
    }
  },
  mixins: [messagesMixin],

  computed: {
    ...mapGetters("stores", ["stores"]),
  },
  created() {
    this.getStores()
  },
  watch: {
    entriesPerPage() {
      this.getStores()
    },
  },
  methods: {
    ...mapActions("stores", ["fetchStores"]),
    ...mapActions("users", ["updateEstablishmentSupplier"]),

    handlePagination({ page, per_page }) {
      this.getStores({ page })
    },

    handleAttachStoresToSupplier(stores) {
      this.loading = true

      this.updateEstablishmentSupplier({
        store_ids: stores,
        supplier_id: this.supplierId,
      })
        .then((res) => {
          this.successToast("Tiendas anexadas correctamente")
          this.$router.push({
            name: "establishment-proveedores",
            params: { id: this.establishmentId },
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorToast("Error", errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    getStores({ page, per_page } = {}) {
      this.loading = true

      this.fetchStores({
        by_establishment: this.$route.params.id,
        meta: {
          pagination: {
            page: page || 1,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then((response) => {
          this.pagination = response.meta.pagination
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast(errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.stores__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;

  .store {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  div {
    display: grid;
    width: 100%;
    order: -1;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
      width: auto;
      order: 0;
    }
  }
}
</style>
